import _uniqueRandom from "unique-random";
var exports = {};
var uniqueRandom = _uniqueRandom;

exports = function (arr) {
  var rand = uniqueRandom(0, arr.length - 1);
  return function () {
    return arr[rand()];
  };
};

export default exports;